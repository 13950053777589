const CONNECTION = {
  DEVELOP: {
    MODE: "develop",
    PORT: 2029,
    HOST: process.env.REACT_APP_API,
  },
  PRODUCTION: {
    MODE: "production",
    PORT: 2022,
    HOST: process.env.REACT_APP_API,
  },
  UAT: {
    MODE: "uat",
    PORT: 2022,
    HOST: process.env.REACT_APP_API,
  },
};
export const USE_CONNECTION_MODE = process.env.REACT_APP_ENV_MODE;

export const CONNECT_API = {
  [CONNECTION.DEVELOP.MODE]: {
    URL: `${CONNECTION.DEVELOP.HOST}`,
    URL_IMAGE: `${CONNECTION.DEVELOP.HOST}`,
  },
  [CONNECTION.PRODUCTION.MODE]: {
    URL: `${CONNECTION.PRODUCTION.HOST}`,
    URL_IMAGE: `${CONNECTION.PRODUCTION.HOST}`,
  },
};

export const CONFIG = {
  API_URL: `${process.env.REACT_APP_API}`,
  API_IMAGE: `${process.env.REACT_APP_API}`,

  //# REGION MASTER
  MASTER: {
    SELECT_OPTIONS: "HRM_API/select_option",
    EVALUATION_EMPLOYEE: "HRM_API/master/evaluationEmployee",
    INDICATOR_STATUS: "HRM_API/master/indicatorStatus",
    INDICATOR_HEADER: "HRM_API/master/indicatorHeader",
    BOSS_APPROVAL: "HRM_API/master/masterBossApproval",
    BOSS_INSPECT: "HRM_API/master/masterBossInspect",
    // DEPARTMENT: "HRM_API/select_PermissionFilter"
    PERMISSIONFILTER: "HRM_API/cent/getOptionFilter",
    DEPARTMENT: "HRM_API/master/department",
    INDICATOR_FACTORY: "HRM_API/kpis/selectIndicator",
    FILTER_FACTORY: "HRM_API/kpis/selectFilterDashboardKPIsFactory",
    FILTER_FACTORY_SUBDEP: "/HRM_API/kpis/selectSubDepartmentName",
  },
  //# END REGION MASTER

  //# REGION MANAGE
  MANAGE: {
    INDICATOR: {
      INSERT: "HRM_API/manage/insertIndicator",
      UPDATE: "HRM_API/manage/updateIndicator",
      INSERT_LICENSE: "HRM_API/manage/insertIndicatorLicense",
      UPDATE_LICENSE: "HRM_API/manage/updateIndicatorLicense",
      UPDATE_TIME: "HRM_API/manage/updateIndicatorTime",
    },
    EVALUATION: {
      INSERT: "HRM_API/manage/insertEvaluationScore",
      UPDATE: "HRM_API/manage/updateEvaluationScore",
    },
  },
  //# END REGION MANAGE

  //# REGION REPORT
  REPORT: {
    INDICATOR_HEADER: "HRM_API/report/indicatorHeader",
    INDICATOR_LINE: "HRM_API/report/indicatorLine",
    INDICATOR_LICENSE: "HRM_API/report/indicatorLicense",
    INDICATOR_LINE_SCORE: "HRM_API/report/indicatorLineScore",
    EVALUATION: "HRM_API/report/evaluation",
    EVALUATION_KPIs: "HRM_API/report/evaluationKPIs",
    EVALUATION_LINE: "HRM_API/report/evaluationLine",
    ALL: "HRM_API/report/all",
    INDICATOR_APPROVAL: "HRM_API/report/indicatorApproval",
    DASHBOARD_KPIS: "HRM_API/kpis/selectDataDashboardKPIs",
  },
  //# END REGION REPORT

  //# REGION KPIS_FACTORY
  FACTORY: {
    INSERT_SCORE: "/HRM_API/kpis/insertScore",
    UPDATE_SCORE: "/HRM_API/kpis/updateScore",
    REPORT_SCORE: "/HRM_API/kpis/selectReportKPIsMonth",
    INSERT_CONFIRM_KPIs: "/HRM_API/kpis/insertHRConfirmKPIsMonth",
    UPDATE_CF_APPROVAL_OT: "/HRM_API/rotf/updateCFApprovalOT",
    UPDATE_CF_LIST_APPROVAL_OT: "/HRM_API/rotf/updateCFListApprovalOT",
    UPDATE_RECALLROT: "/HRM_API/rotf/updateRecallROT",
    DEL_REQUEST_OT_LINE: "/HRM_API/rotf/delRequestOTLine",
    DEL_REQUEST_OT_LINE_ALL: "/HRM_API/rotf/delRequestOTALL",
    UPDATE_STATUS: "/HRM_API/kpis/updateStatus",

    //# REGION OT FACTORY
    INSERT_REQUEST_OT: "/HRM_API/rotf/insertRequestOT",
    UPDATE_REQUEST_OT: "/HRM_API/rotf/updateRequestOT",
    SELECT_USER_REQUEST_OT: "/HRM_API/rotf/selectUserDataFromOT", //'/HRM_API/cent/selectuserData',
    SELECT_SUB_DEPARTMENT: "/HRM_API/cent/selectSubDepartmentName",
    SELECT_FILTER_REQUEST_OT: "/HRM_API/rotf/selectFilterRequestOT",
    SELECT_HRM_FLOW: "/HRM_API/cent/selectHrmFlow",
    SELECT_OPTION_DASHBOARD: "/HRM_API/rotf/selectOption",
    DASHBOARD_OT_FACTORY: "/HRM_API/rotf/selectdashboardOTFac",
    DASHBOARD_OT_FACTORY_APPROVAL: "/HRM_API/rotf/selectdashboardOTFacApproval",
    REPORT_OT_FACTORY: "/HRM_API/rotf/selectDataROT",
    SELECT_FILTER_DASHBOARD_OTFAC: "/HRM_API/rotf/selectFilterDashboard",
    EXPORT_OT_FACTORY: "/HRM_API/rotf/selectDataExportCSV",
    SELECT_WORK_SHIFT: "/HRM_API/rotf/selectOptionWorkshift",

    //# END REGION OT FACTORY
  },
  //# END REGION KPIS_FACTORY
  EVAC: {
    //# REGION OF EVAC
    SELECT_DASHBOARD_REVIEWER: "/HRM_API/evac/checkLoginPermissionReviewer",
    INSERT_REVIEWER: "/HRM_API/evac/insertUserToEva",
    UPDATE_REVIEWER: "/HRM_API/evac/updateUserToEva",

    SELECT_DASHBOARD_EVAC: "/HRM_API/evac/selectDataDashboardEVAC",
    SELECT_DASHBOARD_EVAC_VIEW: "/HRM_API/evac/selectDataDashboardEVAC_View",
    SELECT_DASHBOARD_EVAC_ACTION:
      "/HRM_API/evac/selectDataDashboardEVAC_Action",
    SELECT_DATA_EVAC: "/HRM_API/evac/selectEmployeeDataForEva",
    SELETE_REVIEWER_CURRENT: "/HRM_API/evac/selectEmployeeDataForEvaCurrent",
    SELETE_REVIEWER_UPDATE: "/HRM_API/evac/selectEmployeeDataForEvaUpdate",
    INSERT_EVAC: "/HRM_API/evac/insertEvaluation",

    SELECT_OPTION_FREQUENCY_WORK_WITH:
      "/HRM_API/evac/selectOptionFrequencyWorkWith",
    SELECT_OPTION_DEP_EVAC: "/HRM_API/cent/selectDepartmentName",
    SELECT_QUESTION: "/HRM_API/evac/selectQuestion",
    SELECT_FILTER_DEP_EVAC: "/HRM_API/evac/selectDepInFilter",
    SELECT_FORM_EVAC_ALL: "/HRM_API/evac/selectFilterFormIdAll",
    SELECT_FORM_EVAC_BY_USER_ID: "/HRM_API/evac/selectFilterFormID",
    SELECT_SUMMARY_REPLY_EVAC_BY_FORM:
      "HRM_API/evac/seletSummaryReplyEVACGroupByFormID",
    SELECT_SUMMARY_REPLY_EVAC_BY_DEP: "/HRM_API/evac/selectSummaryReplyEVAC",
    SELECT_SUMMARY_AVG_SCORE_BY_LINE:
      "/HRM_API/evac/selectSummaryAvgScoreByline",
  },
  //# REGION TRAINING_FACTORY
  SELECT_FILTER_DEP: "/HRM_API/evac/selectDepInFilter",
  SELECT_FILTER_SUBDEP: "/HRM_API/cent/selectSubDepartmentName",

  SELECT_OPTION_ADDRESS_BY_POSTCODE: "/HRM_API/empl/getAddressByPostcode",
  SELECT_OPTION_DEP: "/HRM_API/cent/selectDepartmentName",
  SELECT_OPTION_SUBDEP: "/HRM_API/cent/selectSubDepartmentName",
  SELECT_OPTION_MENTOR: "/HRM_API/cent/selectOptionMentor",
  SELECT_OPTION_SUPERVISOR: "/HRM_API/cent/selectSubDepartmentName",
  SELECT_OPTION_FLOW: "/HRM_API/kpis/selectFilterDashboardKPIsFactory",
  SELECT_OPTION_STATUS: "/HRM_API/cent/selectOptionStatus",
  SELECT_OPTION_UTILITY: "/HRM_API/select_OptionUtility",
  SELECT_OPTION_WORKSHIFT_ALL: "/HRM_API/cent/selectOptionWorkShiftAll",
  SELECT_OPTION_DEPARTMENT_ALL: "/HRM_API/cent/selectOptionDepartmentAll",
  SELECT_OPTION_SUB_DEPARTMENT: "/HRM_API/cent/selectOptionSubDepartment",
  SELECT_OPTION_POSITION: "/HRM_API/cent/selectPosition",

  TRAINING: {
    SELECT_OPTION_USER_TRAINING: "./HRM_API/training/selectOptionUserTraining",
    SELECT_OPTION_TRAINING: "./HRM_API/training/selectOptionTraining",
    SELECT_OPTION_TRAINING_COURSE:
      "./HRM_API/training/selectOptionTrainingCourse",
    SELECT_OPTION_TRAINERS: "./HRM_API/training/selectOptionTrainers",
    SELECT_TRAINING_COURSE: "./HRM_API/training/selectTrainingCourse",
    SELECT_TRAINING_USER: "./HRM_API/training/selectTrainingUser",

    INSERT_TRAINING_HISTORY: "/HRM_API/training/insertDocHistory",
    INSERT_TRAINING: "/HRM_API/training/insertTraining",
    UPDATE_TRAINING: "/HRM_API/training/updateTraining",
    VIEW_TRAINING: "/HRM_API/training/selectReportTraining",
    IMPORT_TRAINING: "/HRM_API/training/importTraining",
    REMOVE_TRAINING: "/HRM_API/training/removeTraining",
    INSERT_FILE: "/HRM_API/training/insertFiles",
    INSERT_OTHERS_FILE: "/HRM_API/training/insertOthersFiles",
    DOWNLOAD_FILE: "/HRM_API/training/downloadFile",

    DASHBOARD_INDIVIDUAL: "/HRM_API/training/selectDashboardIndividual",
    DASHBOARD_TRAINING: "/HRM_API/training/selectDashboardTraining",
    DASHBOARD_TRAINERS: "/HRM_API/training/selectDashboardTrainers",
  },
  //# END REGION TRAINING_FACTORY

  PROBATION: {
    DASHBOARD_CONTRACT_BY_DOCNO: "/HRM_API/prob/selectDataProbation", //'/HRM_API/contract/selectContractByDocNo',
    DASHBOARD_CONTRACT: "/HRM_API/prob/selectDashboardProbation", // '/HRM_API/contract/selectDashboardContract',
    DASHBOARD_CONTRACT_NEW: "/HRM_API/prob/selectDashboardProbation-new", // '/HRM_API/contract/selectDashboardContractNew',
    DASHBOARD_CONTRACT_VIEW: "/HRM_API/prob/selectDashboardProbationView", //`/HRM_API/prob/selectDashboardProbationView`
    DASHBOARD_BENEFIT_FITS_OUT: "/HRM_API/select_dataDashboardBenenfitsOut", // '/HRM_API/contract/selectDashboardContract',
    DASHBOARD_TRACK: "/HRM_API/select_dataDashboardBenenfits",
    DASHBOARD_BENEFIT: "/HRM_API/select_dataDashboardBenenfits",

    INSERT_TIMESTAMP_BENEFITS: "/HRM_API/insert_timeStampBenefits", //'/HRM_API/contract/insertContract',
    INSERT_CONTRACT: "/HRM_API/prob/insertProbation", //'/HRM_API/contract/insertContract',
    CONFIRM_CONTRACT: "/HRM_API/insert_timeStampBenefits", //'/HRM_API/contract/insertContract',
    UPDATE_CONTRACT: "/HRM_API/prob/updateProbation", //'/HRM_API/contract/updateContract',
    CONFIRM_RECEIVED_CONTRACT: "/HRM_API/update_ReceivedProbation", //'/HRM_API/contract/updateContract',
  },

  /* ----------------------------- Module Personal ---------------------------- */

  PERSONAL: {
    EXPORT_PROSOFT: "/HRM_API/empl/exportPersonal",
    EXPORT_TIGER_SOFT: "/HRM_API/empl/exportTigerSoft",
    GET_VERIFY_EMP: "/HRM_API/empl/blacklistCheck",
    GET_USER_CODE: "/HRM_API/select_genUserCode",
    INSERT_PERSONAL: "/HRM_API/empl/insertNewEmp",
    INSERT_PERSONAL_ID_CARD: "/HRM_API/empl/insertNewEmpByIdCard",
    GET_IMPORT_PERSONAL_ID_CARD: "/HRM_API/empl/getImportNewEmpByIdCard",
    IMPOER_PERSONAL: "",
    UPDATE_PERSONAL: "/HRM_API/empl/updateEmp",
    SELECT_EMP_PERSONAL: "/HRM_API/empl/findEmp",
    DASHBOARD_PERSONAL: "/HRM_API/empl/selectDataEmployeeAll",
    SELECT_IMPORT_PERSONAL: "/HRM_API/empl/selectImportEmp",
    SELECT_IMPORT_PERSONAL_FAC: "/HRM_API/empl/selectImportEmpFac",
    INSERT_PROBATION_HISTORY: "/HRM_API/empl/insertProbationHistory",
    GET_LINE_NOTI: "/HRM_API/service/lineNoTi",
    GET_DISC: "/HRM_API/empl/selectTypeDISC",
    REMOVE_PERSONAL_ID_CARD_DOCNO: "/HRM_API/empl/removeNewEmpByIdCardDocNo",
  },

  /* ------------------------------- End Module ------------------------------- */
};

export const API_URL = CONFIG.API_URL;
export const URL_IMAGE = CONFIG.API_IMAGE + "/images/";
// console.log({ API_URL, URL_IMAGE })

export default {
  CONFIG,
};
