import React, { useState } from "react";
import { browserHistory } from "react-router";
import { Layout, Col, Form, Input, Row, Spin, Button } from "antd";
import { UserOutlined, LoginOutlined } from "@ant-design/icons";
import { PATHNAME } from "../constants/enums/pathname";
import { useConfirm } from "../screens/conponents/modal/CustomConfirm";
import { useAlert } from "../screens/conponents/modal/CustomAlert";
import { FORGOT_PASSWORD_REQUEST, POST } from "../service";
import { VERSION_TEXT } from "../constants/enums/enumCenter";

const ForgotPasswordRequest = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (value) => {
    const { userName } = value;

    const payload = { userName: userName };
    useConfirm({
      content: `ยืนยันการขอรหัสผ่านใหม่`,
      FnOK: () => onForgotPasswordRequest(payload),
    });
  };

  const onForgotPasswordRequest = async (value) => {
    setLoading(true);
    try {
      const res = await POST(FORGOT_PASSWORD_REQUEST, value);

      const { success } = res;
      if (success) {
        useAlert({
          type: "success",
          title: "ขอรหัสผ่านใหม่สำเร็จ",
          content: "โปรดเช็คอีเมล์ของท่านเพื่อทำการเปลี่ยนรหัสผ่านใหม่",
        });
      }
    } catch (error) {
      useAlert({
        type: "error",
        content: error.message,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Spin spinning={loading}>
      <Layout style={{ backgroundColor: "#FFF" }}>
        <Layout.Header
          style={{
            color: "#fff",
            fontSize: "x-large",
            textAlign: "center",
            backgroundColor: "#3368df",
          }}
        >
          HR - PMS System
        </Layout.Header>
        <Layout.Content
          style={{ padding: 50, minHeight: `calc(100vh - 135px)` }}
        >
          <Form
            form={form}
            onFinish={onFinish}
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
          >
            <Row gutter={[16, 16]} justify="start" align="bottom">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  label="ชื่อผู้ใช้งาน (Username)"
                  name="userName"
                  rules={[
                    {
                      required: true,
                      message:
                        "กรุณากรอก ชื่อผู้ใช้งาน (Username) | ตัวอย่าง : DPXXXXX, DTXXXXX, ZTXXXXX etc. |",
                    },
                  ]}
                >
                  <Input
                    allowClear
                    placeholder={`กรุณากรอก ชื่อผู้ใช้งาน (Username)`}
                    prefix={<UserOutlined />}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]} justify="center" align="bottom">
              <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                <Form.Item>
                  <Button
                    type="primary"
                    style={{ width: "100%" }}
                    icon={<LoginOutlined />}
                    onClick={() => {
                      browserHistory.push(`account${PATHNAME.LOGIN}`);
                    }}
                    target="_blank"
                  >
                    เข้าสู่ระบบ
                  </Button>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    ขอรหัสผ่านใหม่
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Layout.Content>
        <Layout.Footer style={{ textAlign: "center", backgroundColor: "#FFF" }}>
          {`Copyright © Zetta Soft Co.Ltd., | ${VERSION_TEXT}`}
        </Layout.Footer>
      </Layout>
    </Spin>
  );
};

export default ForgotPasswordRequest;
