import { MESSAGE_ERROR, STATUS_VALIDATE } from "..";
import {
  KEY_LOCATION,
  KEY_OPTION,
  KEY_TYPE,
} from "../../../constants/enums/enumCenter";
import moment from "moment";
import { FIELD_EMP, LABEL_EMP } from "./employeeInfo";
import { PATHNAME } from "../../../constants/enums/pathname";

/* -------------------------------------------------------------------------- */
/*                                 LABEL_PAGES                                */
/* -------------------------------------------------------------------------- */

const LABEL_PAGES = {
  //!เปลี่ยนชื่อ Label ที่นี่
  PROFILE_IMAGE: "รูปถ่าย/รูปถ่ายผู้สมัคร",
  TITLE_TH: "คำนำหน้า  (ภาษาไทย)",
  FIRSTNAME_TH: "ชื่อ (ภาษาไทย)",
  LASTNAME_TH: "นามสกุล (ภาษาไทย)",
  NICKNAME_TH: "ชื่อเล่น  (ภาษาไทย)",
  TITLE_EN: "คำนำหน้า (ภาษาอังกฤษ)",
  FIRSTNAME_EN: "ชื่อ (ภาษาอังกฤษ)",
  LASTNAME_EN: "นามสกุล (ภาษาอังกฤษ)",
  NICKNAME_EN: "ชื่อเล่น (ภาษาอังกฤษ)",
  WEIGHT: "น้ำหนัก กก.(Weight )",
  HEIGHT: "ส่วนสูง ซม.(Height)",
  AGE_REGIS: "อายุตอนสมัคร (Age)",
  AGE: "อายุ (Age)",
  DATE_OF_BIRTHDAY: "วัน/เดือน/ปีเกิด (Date of Birth)",
  SEX: "เพศ (Sex)",
  NATIONALITY: "เชื้อชาติ (Nationality)",
  RACE: "สัญชาติ (Race)",
  RELIGION: "ศาสนา (Religion)",
  PERSONAL_ID: "บัตรประชาชนเลขที่ (ID Card No.)",
  PERSONAL_ID_EXP: "หมดอายุ (Date of Expiry)",
  PASSPORT_ID: "หนังสือเดินทาง (Passport No.)",
  PASSPORT_ID_EXP: "หมดอายุ (Date of Expiry)",
  WORKPERMIT_ID: "ใบอนุญาติทำงาน (Work Permit)",
  WORKPERMIT_ID_EXP: "หมดอายุ (Date of Expiry)",
  PERSONAL_STATUS: "สถานภาพ (Marital Status)",
  MILITARYSERVICE: "สถานะทางทหาร (Military Service)",
  MILITARYSERVICE_WHEN: "รอเรียกเกณฑ์ทหาร ในปี พ.ศ.",
  REASON_EXEMPT: "ได้ยกเว้นเกณฑ์ทหารเนื่องจาก (Reason for exemption)",

  SPOUSE_NAME: "ชื่อ-นามสกุล คู่สมรส (Spouse Name)",
  SPOUSE_NUM_CHILDREN: "จำนวนบุตร (Number of children)",
  SPOUSE_TEL: `เบอร์โทรศัพท์คู่สมรส (Spouse's phone number)`,
  SPOUSE_OCCUPATION: `อาชีพคู่สมรส (Spouse's occupation)`,
  SPOUSE_POSITION: `ตำแหน่งงานคู่สมรส (Spouse's Position)`,
  SPOUSE_OFFICE: `ที่ทำงานคู่สมรส (Spouse Office)`,

  SIMILAR_PRESENT: "เหมือนที่อยู่ปัจจุบัน",
  PRESENT_ADDRESS: "ที่อยู่ปัจจุบัน (Present Address)",
  PRESENT_PROVINCE: "จังหวัด (Province)",
  PRESENT_DISTRICT: "อำเภอ/เขต (District)",
  PRESENT_SUB_DISTRICT: "ตำบล / แขวง (Sub-district)",
  PRESENT_POSTCODE: "รหัสไปรษณีย์ (Postcode)",
  FORMER_ADDRESS: "ที่อยู่ตามทะเบียนบ้าน (Former Address)",
  FORMER_PROVINCE: "จังหวัด (Province)",
  FORMER_DISTRICT: "อำเภอ/เขต (District)",
  FORMER_SUB_DISTRICT: "ตำบล / แขวง (Sub-district)",
  FORMER_POSTCODE: "รหัสไปรษณีย์ (Postcode)",

  HOME_TEL: "โทรศัพท์ที่บ้าน (Home Tel)",
  MOBILE_PHONE: "โทรศัพท์มือถือ (Phone Number)",
  LINE_ID: "LINE ID",
  EMAIL: "อีเมลส่วนตัว (Email)",

  EMERGENCY_FULLNAME:
    "ชื่อผู้ติดต่อฉุกเฉิน (Contact person incase of emergency)",
  EMERGENCY_REALATION: "ความสัมพันธ์ (Relationship)",
  EMERGENCY_PHONE: "เบอร์ติดต่อฉุกเฉิน (Phone Number)",
  EMERGENCY_TEL_OTHER: "เบอร์ติดต่อที่บ้าน / ที่ทำงาน (Home / Office tel)",
  EMERGENCY_ADDRESS: "ที่อยู่ผู้ติดต่อฉุกเฉิน (Home / Office Address)",
  DISC: "ผล DISC",
};

/* -------------------------------------------------------------------------- */
/*                                FIELD_PAGES                                */
/* -------------------------------------------------------------------------- */

const FIELD_PAGES = {
  //!เปลี่ยนชื่อ Field ที่นี่
  PROFILE_IMAGE: "profileImage",
  TITLE_TH: "titleTH",
  FIRSTNAME_TH: "firstNameTH",
  LASTNAME_TH: "lastNameTH",
  NICKNAME_TH: "nickNameTH",
  TITLE_EN: "titleEN",
  FIRSTNAME_EN: "firstNameEN",
  LASTNAME_EN: "lastNameEN",
  NICKNAME_EN: "nickNameEN",
  WEIGHT: "weight",
  HEIGHT: "height",
  AGE_REGIS: "ageRegis",
  AGE: "ageRegis",
  DATE_OF_BIRTHDAY: "dateOfBirth",
  SEX: "sex",
  NATIONALITY: "nationality",
  RACE: "race",
  RELIGION: "religion",
  PERSONAL_ID: "personalId",
  PERSONAL_ID_EXP: "personalExpiry",
  PASSPORT_ID: "passportId",
  PASSPORT_ID_EXP: "passportExpiry",
  WORKPERMIT_ID: "workPermitId",
  WORKPERMIT_ID_EXP: "workPermitExpiry",
  PERSONAL_STATUS: "personalStatus",
  MILITARYSERVICE: "militaryService",
  MILITARYSERVICE_WHEN: "militaryWhen",
  REASON_EXEMPT: "reasonExempt",

  SPOUSE_NAME: "spouseName",
  SPOUSE_NUM_CHILDREN: "spouseNumChildren",
  SPOUSE_TEL: "spouseTel",
  SPOUSE_OCCUPATION: "spouseOccupation",
  SPOUSE_POSITION: "spousePosition",
  SPOUSE_OFFICE: "spouseOffice",

  SIMILAR_PRESENT: "similarPresent",
  PRESENT_ADDRESS: "presentAddress",
  PRESENT_PROVINCE: "presentProvince",
  PRESENT_DISTRICT: "presentDistrict",
  PRESENT_SUB_DISTRICT: "presentSubDistrict",
  PRESENT_POSTCODE: "presentPostcode",
  FORMER_ADDRESS: "formerAddress",
  FORMER_PROVINCE: "formerProvince",
  FORMER_DISTRICT: "formerDistrict",
  FORMER_SUB_DISTRICT: "formerSubDistrict",
  FORMER_POSTCODE: "formerPostcode",

  HOME_TEL: "homeTel",
  MOBILE_PHONE: "mobilePhone",
  LINE_ID: "lineId",
  EMAIL: "email",

  EMERGENCY_FULLNAME: "emergencyPerson",
  EMERGENCY_REALATION: "emergencyRelation",
  EMERGENCY_PHONE: "emergencyTel",
  EMERGENCY_TEL_OTHER: "emergencyTelOther",
  EMERGENCY_ADDRESS: "emergencyAddress",

  /* --------------------------------- DISPLAY -------------------------------- */

  FULLNAME_TH: "fullNameTH",
  FULLNAME_EN: "fullNameEN",
  PAYROLL_GROUP: "payrollGroup",
  CURRENT_STATUS: "currentStatus",
  REASON_OUT: "reasonLeaveCode",
  REASON_OUT_TEXT: "reasonLeaveDes",
  DISC: "disc",
  CONTACT_PERIOD: "contractPeriod",
  /* -------------------------------------------------------------------------- */
};

export const FIELD_PERSONAL = FIELD_PAGES;
export const LABEL_PERSONAL = LABEL_PAGES;

/* -------------------------------------------------------------------------- */
/*                                ANOTHER FORM                                */
/* -------------------------------------------------------------------------- */

export const RENDER_ADDRESS_FORM = (props) => {
  const { similar, options, disabled, validateStatus, disabledAll } = props;
  return [
    /* -------------------------------------------------------------------------- */
    {
      span: 24,
      itemprops: {
        name: FIELD_PAGES.SIMILAR_PRESENT,
        label: "",
        rules: [{ required: false, message: MESSAGE_ERROR.CHECKBOX }],
      },
      inputprops: {
        type: KEY_TYPE.CHECKBOX,
        disabled: disabledAll,
        label: LABEL_PAGES.SIMILAR_PRESENT,
        placeholder: "",
      },
    },
    /* -------------------------------------------------------------------------- */
    {
      span: 8,
      itemprops: {
        name: FIELD_PAGES.PRESENT_ADDRESS,
        label: LABEL_PAGES.PRESENT_ADDRESS, //ที่อยู่ปัจจุบัน
        rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
      },
      inputprops: {
        type: KEY_TYPE.INPUT,
        disabled: disabledAll,
        placeholder: "",
      },
    },
    {
      span: 4,
      itemprops: {
        name: FIELD_PAGES.PRESENT_POSTCODE,
        label: LABEL_PAGES.PRESENT_POSTCODE, //รหัสไปรษณีย์
        hasFeedback: true,
        validateStatus: validateStatus[FIELD_PAGES.PRESENT_POSTCODE],
        help:
          validateStatus[FIELD_PAGES.PRESENT_POSTCODE] ===
            STATUS_VALIDATE.ERROR && "ไม่พบข้อมูลในระบบ",
        rules: [{ required: false, message: MESSAGE_ERROR.NUMBER }],
      },
      inputprops: {
        type: KEY_TYPE.NUMBER,
        length: 5,
        disabled: disabledAll,
        placeholder: "",
      },
    },
    {
      span: 4,
      itemprops: {
        name: FIELD_PAGES.PRESENT_PROVINCE,
        label: LABEL_PAGES.PRESENT_PROVINCE, //จังหวัด
        rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
      },
      inputprops: {
        type: KEY_TYPE.SELECT,
        disabled: disabled["PRESENT"] || disabledAll, //! disabled
        placeholder: "",
        showArrow: !disabled["PRESENT"],
        options:
          options[KEY_OPTION.CENTER.OPTION_ADDRESS][
            KEY_OPTION.CENTER.OPTION_ADDRESS_PRESENT
          ][KEY_OPTION.CENTER.OPTION_PROVINCE],
      },
    },
    {
      span: 4,
      itemprops: {
        name: FIELD_PAGES.PRESENT_DISTRICT,
        label: LABEL_PAGES.PRESENT_DISTRICT, //อำเภอ/เขต
        rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
      },
      inputprops: {
        type: KEY_TYPE.SELECT,
        disabled: disabled["PRESENT"] || disabledAll, //! disabled
        placeholder: "",
        showArrow: !disabled["PRESENT"],
        options:
          options[KEY_OPTION.CENTER.OPTION_ADDRESS][
            KEY_OPTION.CENTER.OPTION_ADDRESS_PRESENT
          ][KEY_OPTION.CENTER.OPTION_DISTRICT],
      },
    },
    {
      span: 4,
      itemprops: {
        name: FIELD_PAGES.PRESENT_SUB_DISTRICT,
        label: LABEL_PAGES.PRESENT_SUB_DISTRICT, // ตำบล / แขวง
        rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
      },
      inputprops: {
        type: KEY_TYPE.SELECT,
        disabled: disabled["PRESENT"] || disabledAll, //! disabled
        placeholder: "",
        showArrow: !disabled["PRESENT"],
        options:
          options[KEY_OPTION.CENTER.OPTION_ADDRESS][
            KEY_OPTION.CENTER.OPTION_ADDRESS_PRESENT
          ][KEY_OPTION.CENTER.OPTION_SUB_DISTRICT],
      },
    },
    {
      span: 8,
      itemprops: {
        name: FIELD_PAGES.FORMER_ADDRESS,
        label: LABEL_PAGES.FORMER_ADDRESS, //ที่อยู่ปัจจุบัน
        hidden: similar,
        rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
      },
      inputprops: {
        type: KEY_TYPE.INPUT,
        disabled: similar || disabledAll, //! return true
        placeholder: "",
      },
    },
    {
      span: 4,
      itemprops: {
        name: FIELD_PAGES.FORMER_POSTCODE,
        label: LABEL_PAGES.FORMER_POSTCODE, //รหัสไปรษณีย์
        hidden: similar,
        hasFeedback: true,
        help:
          validateStatus[FIELD_PAGES.FORMER_POSTCODE] ===
            STATUS_VALIDATE.ERROR && "ไม่พบข้อมูลในระบบ",
        validateStatus: validateStatus[FIELD_PAGES.FORMER_POSTCODE],
        rules: [{ required: false, message: MESSAGE_ERROR.NUMBER }],
      },
      inputprops: {
        type: KEY_TYPE.NUMBER,
        disabled: similar || disabledAll, //! return true
        length: 5,
        placeholder: "",
      },
    },
    {
      span: 4,
      itemprops: {
        name: FIELD_PAGES.FORMER_PROVINCE,
        label: LABEL_PAGES.FORMER_PROVINCE, //จังหวัด
        hidden: similar,
        rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
      },
      inputprops: {
        type: KEY_TYPE.SELECT,
        disabled: similar || disabled["FORMER"] || disabledAll, //! return true
        placeholder: "",
        showArrow: !disabled["FORMER"],
        options:
          options[KEY_OPTION.CENTER.OPTION_ADDRESS][
            KEY_OPTION.CENTER.OPTION_ADDRESS_FORMER
          ][KEY_OPTION.CENTER.OPTION_PROVINCE],
      },
    },
    {
      span: 4,
      itemprops: {
        name: FIELD_PAGES.FORMER_DISTRICT,
        label: LABEL_PAGES.FORMER_DISTRICT, //อำเภอ/เขต
        hidden: similar,
        rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
      },
      inputprops: {
        type: KEY_TYPE.SELECT,
        disabled: similar || disabled["FORMER"] || disabledAll, //! return true
        placeholder: "",
        showArrow: !disabled["FORMER"],
        options:
          options[KEY_OPTION.CENTER.OPTION_ADDRESS][
            KEY_OPTION.CENTER.OPTION_ADDRESS_FORMER
          ][KEY_OPTION.CENTER.OPTION_DISTRICT],
      },
    },
    {
      span: 4,
      itemprops: {
        name: FIELD_PAGES.FORMER_SUB_DISTRICT,
        label: LABEL_PAGES.FORMER_SUB_DISTRICT, // ตำบล / แขวง
        hidden: similar,
        rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
      },
      inputprops: {
        type: KEY_TYPE.SELECT,
        disabled: similar || disabled["FORMER"], //! return true
        placeholder: "",
        showArrow: !disabled["FORMER"] || disabledAll,
        options:
          options[KEY_OPTION.CENTER.OPTION_ADDRESS][
            KEY_OPTION.CENTER.OPTION_ADDRESS_FORMER
          ][KEY_OPTION.CENTER.OPTION_SUB_DISTRICT],
      },
    },
  ];
};

export const RENDER_ADDRESS_FORM_VIEW = (props) => {
  const { similar, options, disabled, validateStatus, disabledAll } = props;
  return [
    /* -------------------------------------------------------------------------- */
    {
      span: 24,
      itemprops: {
        name: FIELD_PAGES.SIMILAR_PRESENT,
        label: "",
        rules: [{ required: false, message: MESSAGE_ERROR.CHECKBOX }],
      },
      inputprops: {
        type: KEY_TYPE.CONTENT,
        disabled: disabledAll,
        label: LABEL_PAGES.SIMILAR_PRESENT,
        placeholder: "",
      },
    },
    /* -------------------------------------------------------------------------- */
    {
      span: 8,
      itemprops: {
        name: FIELD_PAGES.PRESENT_ADDRESS,
        label: LABEL_PAGES.PRESENT_ADDRESS, //ที่อยู่ปัจจุบัน
        rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
      },
      inputprops: {
        type: KEY_TYPE.CONTENT,
        disabled: disabledAll,
        placeholder: "",
      },
    },
    {
      span: 4,
      itemprops: {
        name: FIELD_PAGES.PRESENT_POSTCODE,
        label: LABEL_PAGES.PRESENT_POSTCODE, //รหัสไปรษณีย์
        hasFeedback: true,
        validateStatus: validateStatus[FIELD_PAGES.PRESENT_POSTCODE],
        help:
          validateStatus[FIELD_PAGES.PRESENT_POSTCODE] ===
            STATUS_VALIDATE.ERROR && "ไม่พบข้อมูลในระบบ",
        rules: [{ required: false, message: MESSAGE_ERROR.NUMBER }],
      },
      inputprops: {
        type: KEY_TYPE.CONTENT,
        length: 5,
        disabled: disabledAll,
        placeholder: "",
      },
    },
    {
      span: 4,
      itemprops: {
        name: FIELD_PAGES.PRESENT_PROVINCE,
        label: LABEL_PAGES.PRESENT_PROVINCE, //จังหวัด
        rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
      },
      inputprops: {
        type: KEY_TYPE.CONTENT,
        disabled: disabled["PRESENT"] || disabledAll, //! disabled
        placeholder: "",
        showArrow: !disabled["PRESENT"],
        options:
          options[KEY_OPTION.CENTER.OPTION_ADDRESS][
            KEY_OPTION.CENTER.OPTION_ADDRESS_PRESENT
          ][KEY_OPTION.CENTER.OPTION_PROVINCE],
      },
    },
    {
      span: 4,
      itemprops: {
        name: FIELD_PAGES.PRESENT_DISTRICT,
        label: LABEL_PAGES.PRESENT_DISTRICT, //อำเภอ/เขต
        rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
      },
      inputprops: {
        type: KEY_TYPE.CONTENT,
        disabled: disabled["PRESENT"] || disabledAll, //! disabled
        placeholder: "",
        showArrow: !disabled["PRESENT"],
        options:
          options[KEY_OPTION.CENTER.OPTION_ADDRESS][
            KEY_OPTION.CENTER.OPTION_ADDRESS_PRESENT
          ][KEY_OPTION.CENTER.OPTION_DISTRICT],
      },
    },
    {
      span: 4,
      itemprops: {
        name: FIELD_PAGES.PRESENT_SUB_DISTRICT,
        label: LABEL_PAGES.PRESENT_SUB_DISTRICT, // ตำบล / แขวง
        rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
      },
      inputprops: {
        type: KEY_TYPE.CONTENT,
        disabled: disabled["PRESENT"] || disabledAll, //! disabled
        placeholder: "",
        showArrow: !disabled["PRESENT"],
        options:
          options[KEY_OPTION.CENTER.OPTION_ADDRESS][
            KEY_OPTION.CENTER.OPTION_ADDRESS_PRESENT
          ][KEY_OPTION.CENTER.OPTION_SUB_DISTRICT],
      },
    },
    {
      span: 8,
      itemprops: {
        name: FIELD_PAGES.FORMER_ADDRESS,
        label: LABEL_PAGES.FORMER_ADDRESS, //ที่อยู่ปัจจุบัน
        hidden: similar,
        rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
      },
      inputprops: {
        type: KEY_TYPE.CONTENT,
        disabled: similar || disabledAll, //! return true
        placeholder: "",
      },
    },
    {
      span: 4,
      itemprops: {
        name: FIELD_PAGES.FORMER_POSTCODE,
        label: LABEL_PAGES.FORMER_POSTCODE, //รหัสไปรษณีย์
        hidden: similar,
        hasFeedback: true,
        help:
          validateStatus[FIELD_PAGES.FORMER_POSTCODE] ===
            STATUS_VALIDATE.ERROR && "ไม่พบข้อมูลในระบบ",
        validateStatus: validateStatus[FIELD_PAGES.FORMER_POSTCODE],
        rules: [{ required: false, message: MESSAGE_ERROR.NUMBER }],
      },
      inputprops: {
        type: KEY_TYPE.CONTENT,
        disabled: similar || disabledAll, //! return true
        length: 5,
        placeholder: "",
      },
    },
    {
      span: 4,
      itemprops: {
        name: FIELD_PAGES.FORMER_PROVINCE,
        label: LABEL_PAGES.FORMER_PROVINCE, //จังหวัด
        hidden: similar,
        rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
      },
      inputprops: {
        type: KEY_TYPE.CONTENT,
        disabled: similar || disabled["FORMER"] || disabledAll, //! return true
        placeholder: "",
        showArrow: !disabled["FORMER"],
        options:
          options[KEY_OPTION.CENTER.OPTION_ADDRESS][
            KEY_OPTION.CENTER.OPTION_ADDRESS_FORMER
          ][KEY_OPTION.CENTER.OPTION_PROVINCE],
      },
    },
    {
      span: 4,
      itemprops: {
        name: FIELD_PAGES.FORMER_DISTRICT,
        label: LABEL_PAGES.FORMER_DISTRICT, //อำเภอ/เขต
        hidden: similar,
        rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
      },
      inputprops: {
        type: KEY_TYPE.CONTENT,
        disabled: similar || disabled["FORMER"] || disabledAll, //! return true
        placeholder: "",
        showArrow: !disabled["FORMER"],
        options:
          options[KEY_OPTION.CENTER.OPTION_ADDRESS][
            KEY_OPTION.CENTER.OPTION_ADDRESS_FORMER
          ][KEY_OPTION.CENTER.OPTION_DISTRICT],
      },
    },
    {
      span: 4,
      itemprops: {
        name: FIELD_PAGES.FORMER_SUB_DISTRICT,
        label: LABEL_PAGES.FORMER_SUB_DISTRICT, // ตำบล / แขวง
        hidden: similar,
        rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
      },
      inputprops: {
        type: KEY_TYPE.CONTENT,
        disabled: similar || disabled["FORMER"], //! return true
        placeholder: "",
        showArrow: !disabled["FORMER"] || disabledAll,
        options:
          options[KEY_OPTION.CENTER.OPTION_ADDRESS][
            KEY_OPTION.CENTER.OPTION_ADDRESS_FORMER
          ][KEY_OPTION.CENTER.OPTION_SUB_DISTRICT],
      },
    },
  ];
};

/* -------------------------------------------------------------------------- */
/*                                CONTENT_PAGES                               */
/* -------------------------------------------------------------------------- */
export const CONTENT_PERSONAL_DEFAULT = (props) => {
  const { options } = props;
  /**
   * TODO: สามารถแบ่งตาม  location ได้ โดยใช้ switch case
   */
  return [
    /* --------------------------- FORM EMPLOYEE TABS --------------------------- */

    [
      {
        span: 4,
        itemprops: {
          name: FIELD_EMP.EMPLOYEE_ID,
          label: LABEL_EMP.EMPLOYEE_ID, //รหัสพนักงาน
          noStyle: true,
          rules: [
            { 
              required: false, 
              message: MESSAGE_ERROR.INPUT 
            }
          ],
        },
        inputprops: {
          size: "small",
          layout: "vertical",
          label: LABEL_EMP.EMPLOYEE_ID, //รหัสพนักงาน
          type: KEY_TYPE.CONTENT,
          bordered: true,
          disabled: true,
          placeholder: "",
        },
      },
      {
        span: 4,
        itemprops: {
          name: FIELD_EMP.COMPANY,
          label: LABEL_EMP.COMPANY, //บริษัท
          noStyle: true,
          rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          size: "small",
          layout: "vertical",
          label: LABEL_EMP.COMPANY, //บริษัท
          type: KEY_TYPE.CONTENT,
          bordered: true,
          disabled: false,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_COMPANY],
        },
      },
      {
        span: 4,
        itemprops: {
          name: FIELD_EMP.LOCATION,
          label: LABEL_EMP.LOCATION, //สถานที่
          noStyle: true,
          rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          size: "small",
          layout: "vertical",
          label: LABEL_EMP.LOCATION, //สถานที่
          type: KEY_TYPE.CONTENT,
          bordered: true,
          disabled: false,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_LOCATION],
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_EMP.START_DATE_JOB,
          label: LABEL_EMP.START_DATE_JOB, //วันที่เริ่มทำงาน
          noStyle: true,
          rules: [{ required: false, message: MESSAGE_ERROR.DATE_PICKER }],
        },
        inputprops: {
          size: "small",
          layout: "vertical",
          label: LABEL_EMP.START_DATE_JOB, //วันที่เริ่มทำงาน
          type: KEY_TYPE.CONTENT,
          bordered: true,
          disabled: false,
          placeholder: "",
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_EMP.SHIFT_CODE,
          label: LABEL_EMP.SHIFT_CODE, //กะทำงานตั้งต้น
          noStyle: true,
          rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          size: "small",
          layout: "vertical",
          label: LABEL_EMP.SHIFT_CODE, //กะทำงานตั้งต้น
          type: KEY_TYPE.CONTENT,
          bordered: true,
          disabled: false,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_WORKSHIFT_ALL],
        },
      },
      {
        span: 4,
        itemprops: {
          name: FIELD_PAGES.TITLE_TH,
          label: LABEL_PAGES.TITLE_TH, //คำนำหน้าชื่อ (ภาษาไทย)
          noStyle: true,
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          size: "small",
          layout: "vertical",
          bordered: true,
          type: KEY_TYPE.CONTENT,
          label: LABEL_PAGES.TITLE_TH, //คำนำหน้าชื่อ (ภาษาไทย)
          options: options[KEY_OPTION.CENTER.OPTION_TITLE_TH],
          disabled: false,
          placeholder: "",
        },
      },
      {
        span: 8,
        itemprops: {
          name: FIELD_PAGES.FIRSTNAME_TH,
          label: LABEL_PAGES.FIRSTNAME_TH, //ชื่อ (ภาษาไทย)
          noStyle: true,
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          size: "small",
          layout: "vertical",
          label: LABEL_PAGES.FIRSTNAME_TH, //ชื่อ (ภาษาไทย)
          bordered: true,
          type: KEY_TYPE.CONTENT,
          disabled: false,
          placeholder: "",
        },
      },
      {
        span: 8,
        itemprops: {
          name: FIELD_PAGES.LASTNAME_TH,
          label: LABEL_PAGES.LASTNAME_TH, //นามสกุล (ภาษาไทย)
          noStyle: true,
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          size: "small",
          layout: "vertical",
          label: LABEL_PAGES.LASTNAME_TH, //นามสกุล (ภาษาไทย)
          bordered: true,
          type: KEY_TYPE.CONTENT,
          disabled: false,
          placeholder: "",
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_EMP.DEP_NO,
          label: LABEL_EMP.DEP_NO, //แผนก
          noStyle: true,
          rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          size: "small",
          layout: "vertical",
          label: LABEL_EMP.DEP_NO, //แผนก
          type: KEY_TYPE.CONTENT,
          bordered: true,
          disabled: false,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_DEPARTMENT_ALL],
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_EMP.SUB_DEP_NO,
          label: LABEL_EMP.SUB_DEP_NO, //แผนกย่อย
          noStyle: true,
          rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          size: "small",
          layout: "vertical",
          label: LABEL_EMP.SUB_DEP_NO, //แผนกย่อย
          type: KEY_TYPE.CONTENT,
          bordered: true,
          disabled: false,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_SUB_DEPARTMENT],
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_EMP.POSITION_NO,
          label: LABEL_EMP.POSITION_NO, //ตำแหน่งงาน
          noStyle: true,
          rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          size: "small",
          layout: "vertical",
          label: LABEL_EMP.POSITION_NO, //ตำแหน่งงาน
          type: KEY_TYPE.CONTENT,
          bordered: true,
          disabled: false,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_POSITION],
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_EMP.FLOW_HRM,
          label: LABEL_EMP.FLOW_HRM, //สายอนุมัติ
          noStyle: true,
          bordered: true,
          rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          size: "small",
          layout: "vertical",
          label: LABEL_EMP.FLOW_HRM, //สายอนุมัติ
          type: KEY_TYPE.CONTENT,
          bordered: true,
          disabled: false,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_FLOWHRM],
        },
      },
      {
        span: 4,
        itemprops: {
          name: FIELD_PAGES.NICKNAME_TH,
          label: LABEL_PAGES.NICKNAME_TH, //ชื่อเล่น (ภาษาไทย)
          noStyle: true,
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          size: "small",
          layout: "vertical",
          label: LABEL_PAGES.NICKNAME_TH, //ชื่อเล่น (ภาษาไทย)
          bordered: true,
          type: KEY_TYPE.CONTENT,
          disabled: false,
          placeholder: "",
        },
      },
      {
        span: 4,
        itemprops: {
          name: FIELD_PAGES.TITLE_EN,
          label: LABEL_PAGES.TITLE_EN, //คำนำหน้าชื่อ (ภาษาอังกฤษ)
          noStyle: true,
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          size: "small",
          layout: "vertical",
          options: options[KEY_OPTION.CENTER.OPTION_TITLE_EN],
          label: LABEL_PAGES.TITLE_EN, //คำนำหน้าชื่อ (ภาษาอังกฤษ)
          bordered: true,
          type: KEY_TYPE.CONTENT,
          disabled: false,
          placeholder: "",
        },
      },
      {
        span: 8,
        itemprops: {
          name: FIELD_PAGES.FIRSTNAME_EN,
          label: LABEL_PAGES.FIRSTNAME_EN, //ชื่อ (ภาษาอังกฤษ)
          noStyle: true,
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          size: "small",
          layout: "vertical",
          label: LABEL_PAGES.FIRSTNAME_EN, //ชื่อ (ภาษาอังกฤษ)
          bordered: true,
          type: KEY_TYPE.CONTENT,
          disabled: false,
          placeholder: "",
        },
      },
      {
        span: 8,
        itemprops: {
          name: FIELD_PAGES.LASTNAME_EN,
          label: LABEL_PAGES.LASTNAME_EN, //นามสกุล (ภาษาอังกฤษ)
          noStyle: true,
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          size: "small",
          layout: "vertical",
          label: LABEL_PAGES.LASTNAME_EN, //นามสกุล (ภาษาอังกฤษ)
          bordered: true,
          type: KEY_TYPE.CONTENT,
          disabled: false,
          placeholder: "",
        },
      },
      {
        span: 4,
        itemprops: {
          name: FIELD_PAGES.NICKNAME_EN,
          label: LABEL_PAGES.NICKNAME_EN, //ชื่อเล่น (ภาษาอังกฤษ)
          noStyle: true,
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          size: "small",
          layout: "vertical",
          label: LABEL_PAGES.NICKNAME_EN, //ชื่อเล่น (ภาษาอังกฤษ)
          bordered: true,
          type: KEY_TYPE.CONTENT,
          disabled: false,
          placeholder: "",
        },
      },
      {
        span: 8,
        itemprops: {
          name: FIELD_PAGES.PERSONAL_ID,
          label: LABEL_PAGES.PERSONAL_ID, //บัตรประชาชนเลขที่ (ID Card No.)
          noStyle: true,
          rules: [{ required: false, message: MESSAGE_ERROR.ID_CARD }],
        },
        inputprops: {
          size: "small",
          layout: "vertical",
          bordered: true,
          type: KEY_TYPE.CONTENT,
          label: LABEL_PAGES.PERSONAL_ID, //บัตรประชาชนเลขที่ (ID Card No.)
          disabled: false,
          placeholder: "",
        },
      },
      {
        span: 8,
        itemprops: {
          name: FIELD_PAGES.PASSPORT_ID,
          label: LABEL_PAGES.PASSPORT_ID, //หนังสือเดินทาง (Passport No.)
          noStyle: true,
          rules: [{ required: false, message: MESSAGE_ERROR.PASSPORT }],
        },
        inputprops: {
          label: LABEL_PAGES.PASSPORT_ID, //หนังสือเดินทาง (Passport No.)
          size: "small",
          layout: "vertical",
          bordered: true,
          type: KEY_TYPE.CONTENT,
          disabled: false,
          placeholder: "",
        },
      },
      {
        span: 8,
        itemprops: {
          name: FIELD_PAGES.WORKPERMIT_ID,
          label: LABEL_PAGES.WORKPERMIT_ID, //ใบอนุญาติทำงาน (Work Permit)v
          noStyle: true,
          rules: [{ required: false, message: MESSAGE_ERROR.ID_CARD }],
        },
        inputprops: {
          label: LABEL_PAGES.WORKPERMIT_ID, //ใบอนุญาติทำงาน (Work Permit)v
          size: "small",
          layout: "vertical",
          bordered: true,
          type: KEY_TYPE.CONTENT,
          disabled: false,
          placeholder: "",
        },
      },
    ],
  ];
};

export const CONTENT_PERSONAL_INFO = (props) => {
  const { options, pathname } = props;
  /**
   * TODO: สามารถแบ่งตาม  location ได้ โดยใช้ switch case
   */
  return [
    /* --------------------------- FORM EMPLOYEE TABS --------------------------- */

    [
      {
        span: 4,
        itemprops: {
          name: FIELD_PAGES.TITLE_TH,
          label: LABEL_PAGES.TITLE_TH, //คำนำหน้าชื่อ (ภาษาไทย)
          noStyle: true,
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          size: "small",
          layout: "vertical",
          bordered: true,
          type: KEY_TYPE.CONTENT,
          label: LABEL_PAGES.TITLE_TH, //คำนำหน้าชื่อ (ภาษาไทย)
          options: options[KEY_OPTION.CENTER.OPTION_TITLE_TH],
          disabled: false,
          placeholder: "",
        },
      },
      {
        span: 8,
        itemprops: {
          name: FIELD_PAGES.FIRSTNAME_TH,
          label: LABEL_PAGES.FIRSTNAME_TH, //ชื่อ (ภาษาไทย)
          noStyle: true,
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          size: "small",
          layout: "vertical",
          label: LABEL_PAGES.FIRSTNAME_TH, //ชื่อ (ภาษาไทย)
          bordered: true,
          type: KEY_TYPE.CONTENT,
          disabled: false,
          placeholder: "",
        },
      },
      {
        span: 8,
        itemprops: {
          name: FIELD_PAGES.LASTNAME_TH,
          label: LABEL_PAGES.LASTNAME_TH, //นามสกุล (ภาษาไทย)
          noStyle: true,
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          size: "small",
          layout: "vertical",
          label: LABEL_PAGES.LASTNAME_TH, //นามสกุล (ภาษาไทย)
          bordered: true,
          type: KEY_TYPE.CONTENT,
          disabled: false,
          placeholder: "",
        },
      },
      {
        span: 4,
        itemprops: {
          name: FIELD_PAGES.NICKNAME_TH,
          label: LABEL_PAGES.NICKNAME_TH, //ชื่อเล่น (ภาษาไทย)
          noStyle: true,
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          size: "small",
          layout: "vertical",
          label: LABEL_PAGES.NICKNAME_TH, //ชื่อเล่น (ภาษาไทย)
          bordered: true,
          type: KEY_TYPE.CONTENT,
          disabled: false,
          placeholder: "",
        },
      },
      {
        span: 4,
        itemprops: {
          name: FIELD_PAGES.TITLE_EN,
          label: LABEL_PAGES.TITLE_EN, //คำนำหน้าชื่อ (ภาษาอังกฤษ)
          noStyle: true,
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          size: "small",
          layout: "vertical",
          options: options[KEY_OPTION.CENTER.OPTION_TITLE_EN],
          label: LABEL_PAGES.TITLE_EN, //คำนำหน้าชื่อ (ภาษาอังกฤษ)
          bordered: true,
          type: KEY_TYPE.CONTENT,
          disabled: false,
          placeholder: "",
        },
      },
      {
        span: 8,
        itemprops: {
          name: FIELD_PAGES.FIRSTNAME_EN,
          label: LABEL_PAGES.FIRSTNAME_EN, //ชื่อ (ภาษาอังกฤษ)
          noStyle: true,
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          size: "small",
          layout: "vertical",
          label: LABEL_PAGES.FIRSTNAME_EN, //ชื่อ (ภาษาอังกฤษ)
          bordered: true,
          type: KEY_TYPE.CONTENT,
          disabled: false,
          placeholder: "",
        },
      },
      {
        span: 8,
        itemprops: {
          name: FIELD_PAGES.LASTNAME_EN,
          label: LABEL_PAGES.LASTNAME_EN, //นามสกุล (ภาษาอังกฤษ)
          noStyle: true,
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          size: "small",
          layout: "vertical",
          label: LABEL_PAGES.LASTNAME_EN, //นามสกุล (ภาษาอังกฤษ)
          bordered: true,
          type: KEY_TYPE.CONTENT,
          disabled: false,
          placeholder: "",
        },
      },
      {
        span: 4,
        itemprops: {
          name: FIELD_PAGES.NICKNAME_EN,
          label: LABEL_PAGES.NICKNAME_EN, //ชื่อเล่น (ภาษาอังกฤษ)
          noStyle: true,
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          size: "small",
          layout: "vertical",
          label: LABEL_PAGES.NICKNAME_EN, //ชื่อเล่น (ภาษาอังกฤษ)
          bordered: true,
          type: KEY_TYPE.CONTENT,
          disabled: false,
          placeholder: "",
        },
      },
      {
        span: 8,
        itemprops: {
          name: FIELD_PAGES.PERSONAL_ID,
          label: LABEL_PAGES.PERSONAL_ID, //บัตรประชาชนเลขที่ (ID Card No.)
          noStyle: true,
          rules: [{ required: false, message: MESSAGE_ERROR.ID_CARD }],
        },
        inputprops: {
          size: "small",
          layout: "vertical",
          bordered: true,
          type: KEY_TYPE.CONTENT,
          label: LABEL_PAGES.PERSONAL_ID, //บัตรประชาชนเลขที่ (ID Card No.)
          disabled: false,
          placeholder: "",
        },
      },
      {
        span: 8,
        itemprops: {
          name: FIELD_PAGES.PASSPORT_ID,
          label: LABEL_PAGES.PASSPORT_ID, //หนังสือเดินทาง (Passport No.)
          noStyle: true,
          rules: [{ required: false, message: MESSAGE_ERROR.PASSPORT }],
        },
        inputprops: {
          label: LABEL_PAGES.PASSPORT_ID, //หนังสือเดินทาง (Passport No.)
          size: "small",
          layout: "vertical",
          bordered: true,
          type: KEY_TYPE.CONTENT,
          disabled: false,
          placeholder: "",
        },
      },
      {
        span: 8,
        itemprops: {
          name: FIELD_PAGES.WORKPERMIT_ID,
          label: LABEL_PAGES.WORKPERMIT_ID, //ใบอนุญาติทำงาน (Work Permit)v
          noStyle: true,
          rules: [{ required: false, message: MESSAGE_ERROR.ID_CARD }],
        },
        inputprops: {
          label: LABEL_PAGES.WORKPERMIT_ID, //ใบอนุญาติทำงาน (Work Permit)v
          size: "small",
          layout: "vertical",
          bordered: true,
          type: KEY_TYPE.CONTENT,
          disabled: false,
          placeholder: "",
        },
      },
      {
        span: pathname === PATHNAME.INSERT_PERSONAL ? 0 : 24, // DISC
        itemprops: {
          name: FIELD_PAGES.DISC,
          label: LABEL_PAGES.DISC, //ใบอนุญาติทำงาน (Work Permit)v
          noStyle: true,
          rules: [{ required: false, message: MESSAGE_ERROR.ID_CARD }],
        },
        inputprops: {
          label: LABEL_PAGES.DISC, //ใบอนุญาติทำงาน (Work Permit)v
          layout: "vertical",
          type: KEY_TYPE.BUTTON,
          disabled: false,
          style: {
            marginTop: "10px",
          },
          onClick: () => props.onClick && props.onClick.onPressDISC(),
        },
      },
    ],
  ];
};

export const CONTENT_PERSONAL = (props) => {
  const {
    location,
    options,
    renderAddressForm,
    disabled = {},
    disabledAll,
  } = props;
  /**
   * TODO: สามารถแบ่งตาม  location ได้ โดยใช้ switch case
   */
  return [
    [
      {
        span: 24,
        itemprops: {
          name: FIELD_PAGES.PROFILE_IMAGE,
          label: LABEL_PAGES.PROFILE_IMAGE, //รูปถ่าย/รูปถ่ายผู้สมัคร
          rules: [{ required: false, message: MESSAGE_ERROR.UPLOAD_IMAGE }],
        },
        inputprops: {
          name: FIELD_PAGES.PROFILE_IMAGE,
          type: KEY_TYPE.UPLOAD_IMAGE,
          listType: "picture-card",
          multiple: false,
          disabled: disabledAll,
          placeholder: "",
          size: 3,
          accept: "image/jpeg,image/png",
          max: 1,
        },
      },
      {
        span: 5,
        itemprops: {
          name: FIELD_PAGES.WEIGHT,
          label: LABEL_PAGES.WEIGHT, //น้ำหนัก (Weight)
          rules: [{ required: false, message: MESSAGE_ERROR.DECIMAL }],
        },
        inputprops: {
          type: KEY_TYPE.DECIMAL,
          max: 300,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 5,
        itemprops: {
          name: FIELD_PAGES.HEIGHT,
          label: LABEL_PAGES.HEIGHT, //ส่วนสูง (Height)
          rules: [{ required: false, message: MESSAGE_ERROR.DECIMAL }],
        },
        inputprops: {
          type: KEY_TYPE.DECIMAL,
          max: 300,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 5,
        itemprops: {
          name: FIELD_PAGES.SEX,
          label: LABEL_PAGES.SEX, //เพศ (Sex)
          rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          type: KEY_TYPE.SELECT,
          disabled: disabledAll,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_SEX],
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.DATE_OF_BIRTHDAY,
          label: LABEL_PAGES.DATE_OF_BIRTHDAY, //วัน/เดือน/ปีเกิด (Date of Birth)
          rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          type: KEY_TYPE.DATE_PICKER,
          placeholder: "",
          disabledDate: (current) => current && current > moment(),
          defaultPickerValue: moment("1990-01-01"),
          disabled: disabledAll,
        },
      },
      {
        span: 3,
        itemprops: {
          name: FIELD_PAGES.AGE,
          label: LABEL_PAGES.AGE, //อายุ (Age)
          rules: [{ required: false, message: MESSAGE_ERROR.NUMBER }],
        },
        inputprops: {
          type: KEY_TYPE.NUMBER,
          length: 2,
          disabled: true,
          placeholder: "",
        },
      },
      {
        span: 5,
        itemprops: {
          name: FIELD_PAGES.NATIONALITY,
          label: LABEL_PAGES.NATIONALITY, //เชื้อชาติ (Nationality)
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          type: KEY_TYPE.INPUT,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 5,
        itemprops: {
          name: FIELD_PAGES.RACE,
          label: LABEL_PAGES.RACE, //สัญชาติ (Race)
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          type: KEY_TYPE.INPUT,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 5,
        itemprops: {
          name: FIELD_PAGES.RELIGION,
          label: LABEL_PAGES.RELIGION, //ศาสนา (Religion)
          rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          type: KEY_TYPE.SELECT,
          disabled: disabledAll,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_RELIGION],
        },
      },
      {
        span: 9,
        itemprops: {
          name: FIELD_PAGES.PERSONAL_STATUS,
          label: LABEL_PAGES.PERSONAL_STATUS, //สถานภาพ (Marital Status)
          rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          type: KEY_TYPE.SELECT,
          disabled: disabledAll,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_PERSONLA_STATUS],
        },
      },
      {
        span: 5,
        itemprops: {
          name: FIELD_PAGES.MILITARYSERVICE,
          label: LABEL_PAGES.MILITARYSERVICE, //สถานะทางทหาร (Military Service)
          rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          type: KEY_TYPE.SELECT,
          disabled: disabledAll,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_MILITARYSERVICE],
        },
      },
      {
        span: 5,
        itemprops: {
          name: FIELD_PAGES.MILITARYSERVICE_WHEN,
          label: LABEL_PAGES.MILITARYSERVICE_WHEN, //รอเรียกเกณฑ์ทหาร ในปี พ.ศ.
          rules: [{ required: false, message: MESSAGE_ERROR.DATE_PICKER }],
        },
        inputprops: {
          type: KEY_TYPE.DATE_PICKER,
          picker: "year",
          disabled: !disabled[FIELD_PAGES.MILITARYSERVICE_WHEN] || disabledAll,
          placeholder: "",
          disabledDate: (current) =>
            current && current < moment().endOf("year"),
        },
      },
      {
        span: 14,
        itemprops: {
          name: FIELD_PAGES.REASON_EXEMPT,
          label: LABEL_PAGES.REASON_EXEMPT, //ได้ยกเว้นเกณฑ์ทหารเนื่องจาก (Reason for exemption)
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          type: KEY_TYPE.INPUT,
          disabled: !disabled[FIELD_PAGES.REASON_EXEMPT] || disabledAll,
          placeholder: "",
        },
      },
      {
        span: 24,
        inputprops: {
          type: KEY_TYPE.DIVIDER,
          title: "ข้อมูลที่อยู่",
        },
      },
      /* -------------------------------------------------------------------------- */
      ...renderAddressForm, //TODO : Render Form Address
      /* -------------------------------------------------------------------------- */
      {
        span: 24,
        inputprops: {
          type: KEY_TYPE.DIVIDER,
          title: "ข้อมูลคู่สมรส",
        },
      },
      /* -------------------------------------------------------------------------- */
      {
        span: 12,
        itemprops: {
          name: FIELD_PAGES.SPOUSE_NAME,
          label: LABEL_PAGES.SPOUSE_NAME, //ชื่อ-นามสกุล คู่สมรส
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          type: KEY_TYPE.INPUT,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 12,
        itemprops: {
          name: FIELD_PAGES.SPOUSE_OCCUPATION,
          label: LABEL_PAGES.SPOUSE_OCCUPATION, //อาชีพคู่สมรส
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          type: KEY_TYPE.INPUT,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.SPOUSE_NUM_CHILDREN,
          label: LABEL_PAGES.SPOUSE_NUM_CHILDREN, //จำนวนบุตร
          rules: [{ required: false, message: MESSAGE_ERROR.NUMBER, len: 1 }],
        },
        inputprops: {
          type: KEY_TYPE.NUMBER,
          length: 1,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.SPOUSE_TEL,
          label: LABEL_PAGES.SPOUSE_TEL, //เบอร์โทรศัพท์คู่สมรส
          rules: [{ required: false, message: MESSAGE_ERROR.TEL, len: 10 }],
        },
        inputprops: {
          type: KEY_TYPE.TEL,
          length: 10,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 12,
        itemprops: {
          name: FIELD_PAGES.SPOUSE_POSITION,
          label: LABEL_PAGES.SPOUSE_POSITION, //ตำแหน่งงานคู่สมรส
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          type: KEY_TYPE.INPUT,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 24,
        itemprops: {
          name: FIELD_PAGES.SPOUSE_OFFICE,
          label: LABEL_PAGES.SPOUSE_OFFICE, //ที่ทำงานคู่สมรส
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT_AREA }],
        },
        inputprops: {
          type: KEY_TYPE.INPUT_AREA,
          disabled: disabledAll,
          placeholder: "",
        },
      },

      /* -------------------------------------------------------------------------- */
      {
        span: 24,
        inputprops: {
          type: KEY_TYPE.DIVIDER,
          title: "ผู้ติดต่อฉุกเฉิน ",
        },
      },
      /* -------------------------------------------------------------------------- */

      {
        span: 12,
        itemprops: {
          name: FIELD_PAGES.EMERGENCY_FULLNAME,
          label: LABEL_PAGES.EMERGENCY_FULLNAME, //ชื่อผู้ติดต่อฉุกเฉิน
          rules: [
            {
              required: location === KEY_LOCATION.FACTORY,
              message: MESSAGE_ERROR.INPUT,
            },
          ],
        },
        inputprops: {
          type: KEY_TYPE.INPUT,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 12,
        itemprops: {
          name: FIELD_PAGES.EMERGENCY_PHONE,
          label: LABEL_PAGES.EMERGENCY_PHONE, // 'เบอร์ติดต่อฉุกเฉิน
          rules: [
            {
              required: location === KEY_LOCATION.FACTORY,
              message: MESSAGE_ERROR.TEL,
            },
          ],
        },
        inputprops: {
          type: KEY_TYPE.TEL,
          length: 10,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 12,
        itemprops: {
          name: FIELD_PAGES.EMERGENCY_REALATION,
          label: LABEL_PAGES.EMERGENCY_REALATION, //ความสัมพันธ์ (Relationship)
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          type: KEY_TYPE.INPUT,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 12,
        itemprops: {
          name: FIELD_PAGES.EMERGENCY_TEL_OTHER,
          label: LABEL_PAGES.EMERGENCY_TEL_OTHER, //เบอร์ติดต่อที่บ้าน / ที่ทำงาน
          rules: [{ required: false, message: MESSAGE_ERROR.TEL }],
        },
        inputprops: {
          type: KEY_TYPE.TEL,
          length: 10,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 24,
        itemprops: {
          name: FIELD_PAGES.EMERGENCY_ADDRESS,
          label: LABEL_PAGES.EMERGENCY_ADDRESS, //ที่อยู่ผู้ติดต่อฉุกเฉิน
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT_AREA }],
        },
        inputprops: {
          type: KEY_TYPE.INPUT_AREA,
          disabled: disabledAll,
          placeholder: "",
        },
      },
    ],
  ];
};
