import React from "react";
import { CONFIG } from "../../components/connectAPI";

export const API = {
  localhost: process.env.REACT_APP_API_DEVELOP,
  TEST_SERVER: process.env.REACT_APP_API_TEST_SERVER,
  testhost: process.env.REACT_APP_APP_TEST,
  testByIP: process.env.REACT_APP_API_PRODUCTION_IP,
};

export const POST_CONNECT = {
  DEVELOP: 2020,
  TEST: 3020,
};

//export const USE_CONNECT_MODE_AUTO = API.localhost + POST_CONNECT.DEVELOP;
//export const USE_CONNECT_MODE_AUTO = API.testhost + POST_CONNECT.TEST;
//export const USE_CONNECT_MODE_AUTO = API.testByIP + POST_CONNECT.DEVELOP;
export const USE_CONNECT_MODE_AUTO = CONFIG.API_URL;

// export const USE_CONNECT_MODE_AUTO =
//   process.env.NODE_ENV == "development"
//     ? API.localhost + POST_CONNECT.DEVELOP
//     : API.testhost + POST_CONNECT.TEST;

export const SubDepartment = {
  get_dep: {
    url: `/HRM_API/subdep/selectDepartments`,
  },
  get_sub_dep: {
    url: `/HRM_API/subdep/selectSubDepartments`,
  },
  get_emp_by_sub_dep: {
    url: `/HRM_API/subdep/selectEmployeeList/`,
  },
  get_one_sub_dep: {
    url: `/HRM_API/subdep/selectSubDepartments`,
  },
  post_sup_dep: {
    url: `/HRM_API/subdep/insertSubDepartment`,
  },
  put_sup_dep: {
    url: `/HRM_API/subdep/updateSubDepartment/`,
  },
  delete_sup_dep: {
    url: `/HRM_API/subdep/`,
  },
};

export const Position = {
  GET_Company: `/HRM_API/position/selectCompanyName`,
  GET_Employee_LIST: `/HRM_API/position/selectEmployeeList/`, // :positionCode
  GET_SEARCH_Position: `/HRM_API/position/selectPosition`, //  queries: position and companyCode
  GET_Option_Position: `/HRM_API/cent/selectPosition`, //  queries: position and companyCode
  GET_SEARCH_Position_BY_ID: `/HRM_API/position/selectPosition/`, //  :positionCode
  POST_CREATE_Position: `/HRM_API/position/insertPosition/`, //  :positionCode
  PUT_UPDATE_Position: `/HRM_API/position/updatePosition/`, //  :positionCode
  PUT_DEACTIVE: `/HRM_API/position/`, // :positionCode/inactive
  PUT_ACTIVE: `/HRM_API/position/`, // :positionCode/active
};

export const Sub_Department = {
  GET_Department: `/HRM_API/subdep/selectDepartments`, // queries: companyCode
  GET_Employee_List: `/HRM_API/subdep/selectEmployeeList/`, // :subDepNo
  GET_Option_Sub_Department: `/HRM_API/cent/selectOptionSubDepartment`, // queries: subDepName, depNo
  GET_Search_Sub_Department: `/HRM_API/subdep/selectSubDepartments`, // queries: subDepName, depNo
  GET_UPDATE_Sub_Department: `/HRM_API/subdep/selectSubDepartments/`, // :depNo/:subDepNo
  POST_INSERT_Sub_Department: `/HRM_API/subdep/insertSubDepartment`,
  PUT_UPDATE_Sub_Department: `/HRM_API/subdep/updateSubDepartment/`, // :depNo/:subDepNo
  PUT_Deactive_Sub_Department: `/HRM_API/subdep/`, // :depNo/:subDepNo/(inactive,active)
};

export const ApprovalFlow = {
  GET_GenerateFlowId: `/HRM_API/approvalFlow/generateId/`, // /HRM_API/approvalFlow/generateId/:company/:location
  GET_ApproveFlow: `/HRM_API/approvalFlow/selectApprovalFlows`, // queries: flowId and approver
  GET_EmployeeList_BY_ID: `/HRM_API/approvalFlow/selectEmployeeList/`, // :flowId
  GET_UPDATE_Approve: `/HRM_API/approvalFlow/selectApprovalFlows/`, // :flowId
  GET_Company: `/HRM_API/position/selectCompanyName`, // :flowId
  GET_Employee: `/HRM_API/hist/empName`,
  POST_INSERT_Approve: `/HRM_API/approvalFlow/insertApprovalFlow`,
  PUT_UPDATE_Approve: `/HRM_API/approvalFlow/updateApprovalFlow`,
  DELETE_ApproveFlow: `/HRM_API/approvalFlow/deleteApprovalFlow/`, //:flowId
  DELETE_INACTIVE_ApproveFlow: `/HRM_API/approvalFlow/`, //:flowId
};

export const PermissionView = {
  GET_GenerateID: `/HRM_API/permView/generateId`, // /HRM_API/approvalFlow/generateId/:company/:location
  GET_EmployeeList_BY_ID: `/HRM_API/permView/selectEmp/`, // :flowId
  GET_SEARCH_BY_ID: `/HRM_API/permView/selectPermissionView/`, // :flowId
  GET_Company: `/HRM_API/position/selectCompanyName`, // :flowId
  //GET_Employee: `/HRM_API/hist/empName`,
  GET_Employee: `/HRM_API/permView/empName`,
  POST_SEARCH_VIEW: `/HRM_API/permView/selectPermissionView`,
  POST_CREATE_VIEW: `/HRM_API/permView/insertPermissionView`,
  PUT_UPDATE_VIEW: `/HRM_API/permView/updatePermissionView`,
  PUT_ACTIVE_VIEW: `/HRM_API/permView/`,
  DELETE_VIEW: `/HRM_API/permView/deletePermissionView/`, //:flowId
};

export const History = {
  GET_Employee: `/HRM_API/hist/empName`,
  GET_Employee_Position: `/HRM_API/position/selectEmployeeList/`, //:positionCode
  GET_Employee_Sub_Department: `/HRM_API/subdep/selectEmployeeList/`, //:subDepNo
  GET_Employee_Approval_Flow: `/HRM_API/approvalFlow/selectEmployeeList/`, //:flowId
  GET_Employee_Permission_View: `/HRM_API/permView/selectEmp/`, //:flowId
  PUT_Change_Department: `/HRM_API/hist/updateEmpDepartment`,
  PUT_Change_Position: `/HRM_API/hist/updateEmpPosition`,
  PUT_Change_Approval_Flow: `/HRM_API/hist/updateEmpApprovalFlow`,
  PUT_Change_Permission_View: `/HRM_API/hist/updateEmpFlowView`,
  PUT_Change_Company: `/HRM_API/hist/updateEmpCompany`,
  POST_GET_Prev_Change: `/HRM_API/hist/prevConflict`,
  DELETE_Clear_Change: `/HRM_API/hist/clearPrevUpdate`,
  // NEW API
  POST_Change_Approval_Flow: `/HRM_API/hist/changeEmpApprovalFlow`,
  POST_Change_Departmant: `/HRM_API/hist/changeEmpDepartment`,
  POST_Change_Sub_Departmant: `/HRM_API/hist/changeEmpSubDepartment`,
  POST_Change_Position: `/HRM_API/hist/changeEmpPosition`,
  POST_Change_Permission_View: `/HRM_API/hist/changeEmpFlowView`,
  POST_Change_Company: `/HRM_API/hist/changeEmpCompany`,
  POST_Verify: `/HRM_API/hist/verifyChange`,
  DELETE_Clear_Conflict: `/HRM_API/hist/clearPrevUpdate`,
  GET_Employee_CompanyCode: `/HRM_API/hist/empName`, // companyCode
  PUT_Change_ActionDate: `/HRM_API/hist/updateChangeActionDate`,
};

export const OJT = {
  GET_Position_OJT: `/HRM_API/ojt/selectPosition`, //queries: companyCode, positionCode
  GET_Training_Group: `/HRM_API/ojt/selectTrainingGroup`,
  GET_Training_Type: `/HRM_API/ojt/selectTrainingType`,
  GET_Training_ALL: `/HRM_API/ojt/selectTraining`, // queries: :trainingNo
  GET_Search_Course: `/HRM_API/ojt/selectCourse`, // queries: positionCode
  GET_TrainingMethod: `/HRM_API/cent/selectOptionStatus?type=trainingMethod`,
  GET_ReasonLeaveCode: `/HRM_API/cent/selectOptionStatus?type=reasonLeaveCode`,
  GET_CurrentStatus: `/HRM_API/cent/selectOptionStatus?type=currentStatus`,
  POST_INSERT_Training: `/HRM_API/ojt/insertTraining`,
  POST_INSERT_Course: `/HRM_API/ojt/insertCourse`,
  PUT_Training: `/HRM_API/ojt/updateTraining/`, // :trainingNo
  PUT_Course: `/HRM_API/ojt/updateCourse`, // :trainingNo
  PUT_Course_Activate: `/HRM_API/ojt/course/`, // /HRM_API/ojt/course/:positionCode/"active/inactive"
};

export const KPIs = {
  GET_Position_KPIs: `/HRM_API/kpisMaster/selectPosition`, //queries: companyCode, positionCode
  GET_GenerateId: `/HRM_API/kpisMaster/generateId`,
  GET_SELECT_KPIs: `/HRM_API/kpisMaster/selectKPI`, // queries: :positionCode, :indicatorNo
  POST_INSERT_KPIs: `/HRM_API/kpisMaster/insertKPI`,
  PUT_UPDATE_KPIs: `/HRM_API/kpisMaster/updateKPI`,
  PUT_KPIs_Activate: `/HRM_API/kpisMaster/`, // :positionCode
  POST_ActiveByLine: `/HRM_API/kpisMaster/activateKPIbyLine`,
  POST_DeActiveByLine: `/HRM_API/kpisMaster/deActivateKPIbyLine`,
};

export const SSO = {
  GET_Employee_NO_SSO: `/HRM_API/sso/empName`, // queries: companyCode
  GET_Province: `/HRM_API/sso/selectProvince`, // queries: province
  GET_Download_Excel: `/HRM_API/sso/generateReport`, // queries: startDateJob, company, department, already=1
  GET_Report: `/HRM_API/sso/selectReport`, // queries: startDateJob, company, department, userId, fill
  GET_hospital: `/HRM_API/sso/selectHospital`, // queries: provinceId
  POST_Insert_SSO: `/HRM_API/sso/insertSSO`,
  POST_getDashboardActionNewEmp: `/HRM_API/sso/selectDashboardActionNewEmp`,
  POST_getDashboardActionOutEmp: `/HRM_API/sso/selectDashboardActionOutEmp`,
  POST_Insert_Send_SSO: `/HRM_API/benefits/insertStampBenefits`,
};

export const CustomizeRole = {
  GET_Default_Info: `/HRM_API/cusRole/info`,
  GET_User_Menu: `/HRM_API/cusRole/getUserMenu/`, // queries: userId
  POST_Add_Permission: `/HRM_API/cusRole/insertUserPermission`,
  PUT_Update_Permission: `/HRM_API/cusRole/updateUserPermission`, // queries: provinceId
  POST_Verift_Login: `/HRM_API/cent/verifyLogin`,
  POST_GET_Navigator: `/HRM_API/cent/getNavigator`, // Body Raw : userId, location
  POST_getPermissionByRole: `/HRM_API/cusRole/getPermissionByRole`, // Body Raw : roles
};

export const Report = {
  GET_SELECT_All_info: `/HRM_API/report/selectAll`, // queries: userId
  GET_Level_Worker: `/HRM_API/report/selectLevelWorker`, // queries: companyCode, depNo, statusWorker, location
  GET_Age_Employee: `/HRM_API/report/selectAge`, // queries: companyCode, depNo, statusWorker, location, subDepNo
  GET_Age_Work: `/HRM_API/report/selectAgeOfWork`, // queries: companyCode, depNo, statusWorker, location, subDepNo, filterBy:[department, subDepartment]
  GET_Status_Worker_Report: `/HRM_API/report/selectStatusWorker`,
  GET_Transfer_History: `/HRM_API/report/selectHistory`, // queries: from(date), to(date)
  POST_GET_Employee_Summary: `/HRM_API/report/selectEmpAmount`, // queries: month(date), from(date), isSubDepartment(boolean), company, depNo, location, filterBy:[department, subDep], || BodyRaw: month(date)
  POST_selectDataCrime: `/HRM_API/report/selectDataCrime`,
};

export const JG_PG = {
  GET_Data_JGPG: `/HRM_API/empl/selectDataJGPG`, // body raw : [company, depNo]
  POST_UPDATE_JG_PG: `/HRM_API/empl/updateDataJGPG`, // body raw : [company, depNo]
};

export const Crime = {
  POST_Search_Crime: `/HRM_API/empl/selectDataDashboardCrimeAction`, // body raw : [company, depNo, subDepNo, userId, startDate, endDate]
  POST_Insert_Crime: `/HRM_API/empl/insertDataCrimeMultiple`,
  POST_Crime_Header: `/HRM_API/empl/selectDataCrimesHeader`,
  POST_selectDataCrimeLine: `/HRM_API/empl/selectDataCrimeLine`,
  POST_updateDataCrimeMultiple: `/HRM_API/empl/updateDataCrimeMultiple`,
  GET_Option_Crime: `/HRM_API/cent/selectOptionStatus?type=crimeResultCode`,
};

export const Probation = {
  POST_Select_Probation: `/HRM_API/prob/selectDashboardProbationMaster`, // body raw : [company, depNo, subDepNo, userId, startDate, endDate]
  POST_Insert_Probation: `/HRM_API/prob/insertMasterProbation`,
  POST_Delete_Probation: `/HRM_API/prob/delMasterProbation`,
  POST_verifyDataInPosition: `/HRM_API/prob/verifyDataInPosition`, // body raw : [positionNo]
};

export const CenterAPI = {
  POST_VerifyLogin: `/HRM_API/cent/verifyLogin`, // body raw : [username, password]
  POST_GetNavigator: `/HRM_API/cent/getNavigator`, // body raw : [userId, location]
  GET_OptionStatusMainCode: `/HRM_API/cent/selectOptionStatus?type=statusMainCode`,
  GET_OptionContractExtention: `/HRM_API/cent/selectOptionStatus?type=contractExtension`,
  GET_OptionStatusWorker: `/HRM_API/cent/selectOptionStatus?type=status_worker`,
  GET_OptionRecruiterGrade: `/HRM_API/cent/selectOptionStatus?type=recruiterGrade`,
  GET_OptionRecruiterScore: `/HRM_API/cent/selectOptionStatus?type=recruiterScore`,
  GET_OptionStatusProbation: `/HRM_API/cent/selectOptionStatus?type=statusProbation`,
  GET_OptionInsurancePlan: `/HRM_API/cent/selectOptionStatus?type=insurancePlan`,
  GET_OptionGrade: `/HRM_API/cent/selectOptionStatus?type=grade`,
  GET_OptionContractExtension: `/HRM_API/cent/selectOptionStatus?type=contractExtension`,
  GET_OptionPayrollGroup: `/HRM_API/cent/selectOptionStatus?type=payrollGroup`,
  GET_OptionMovement: `/HRM_API/cent/selectOptionStatus?type=movement`,
  GET_OptionHrTeam: `/HRM_API/empl/selectDataHrTeam`,
};

export const Benefit = {
  POST_selectDashboardBenefitsNewEmp: `/HRM_API/benefits/selectDashboardBenefitsNewEmp`,
  POST_selectDashboardBenefitsNewEmpTemp: `/HRM_API/benefits/selectDashboardBenefitsNewEmpTemp`,
  POST_selectDashboardBenefitsOut: `/HRM_API/benefits/selectDashboardBenefitsOut`,
  POST_sendSSO: `/HRM_API/benefits/insertStampBenefits`,
};

export const Transfers = {
  POST_selectDashboardMovement: `/HRM_API/movement/selectDashboardMovement`,
  POST_selectDataLogsNotTransfer: `/HRM_API/movement/selectDataLogsNotTransfer`,
  POST_rollbackMovement: `/HRM_API/movement/rollbackMovement`,
  POST_insertDataMovement: `/HRM_API/movement/insertDataMovement`,
};
